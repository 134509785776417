import React from "react";

function App() {
  return (
    <div className="app">
      <header className="app-header">
        <p>Huh, looks like there's nothing here...</p>
      </header>
    </div>
  );
}

export default App;
